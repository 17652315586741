import styled, { keyframes } from 'styled-components'
import { media, parseColor } from '@superlogica/design-ui'
import { rem, rgba } from 'polished'

import { TabsProps } from './Tabs'

const show = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

type WrapperProps = Required<Pick<TabsProps, 'color'>>

export const Item = styled.div`
  display: none;
  opacity: 0;
  min-height: 300px;

  &.active {
    display: block;
    opacity: 1;
    animation: 400ms ease-in-out ${show};
  }
`

export const NavLink = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: all linear 150ms;
  font-weight: 700;
  font-size: ${rem(14)};
  letter-spacing: 1px;
  text-transform: uppercase;
  height: 74px;

  ${({ theme: { colors } }) => `
    color: ${rgba(parseColor(colors.babar0), 0.8)};

    &.active {
      color: ${parseColor(colors.babar0)};
      font-size: ${rem(16)};
      height: 84px;
    }
  `}

  ${media.lessThan('sm')} {
    padding: 0 12px;
    font-size: ${rem(10)};

    &.active {
      font-size: ${rem(12)};
    }
  }

  ${media.lessThan('xxs')} {
    height: 54px;

    &.active {
      height: 64px;
    }
  }
`

export const NavItem = styled.li`
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;

  &:first-child {
    ${NavLink} {
      border-top-left-radius: 6px;
    }
  }

  &:last-child {
    ${NavLink} {
      border-top-right-radius: 6px;
    }
  }
`

export const NavList = styled.ul`
  display: flex;
  height: 84px;
  align-items: flex-end;

  ${media.lessThan('xxs')} {
    height: 64px;

    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export const Nav = styled.nav``

export const Content = styled.div`
  padding: 48px 64px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  ${({ theme: { colors } }) => `
    background-color: ${parseColor(colors.babar0)};
  `}

  ${media.lessThan('sm')} {
    padding: 32px;
  }
`

export const Wrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['color'].includes(prop as string)
})<WrapperProps>`
  ${({ theme: { colors }, color }) => `
    ${NavItem} {
      &:not(:last-child) {
        ${NavLink} {
          border-right: 1px solid ${parseColor(colors[`${color}700` as Color])};
        }
      }
    }

    ${NavLink} {
      background-color: ${parseColor(colors[`${color}500` as Color])};

      &.active {
        background-color: ${parseColor(colors[`${color}700` as Color])};
      }
    }
  `}
`
