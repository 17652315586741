import {
  IGatsbyImageData,
  withArtDirection,
  getImage
} from 'gatsby-plugin-image'
import { breakpoints, parseSize } from '@superlogica/design-ui'

interface useResponsiveImageProps {
  desktop: IGatsbyImageData
  mobile: IGatsbyImageData
  breakpoint?: Breakpoint
}

const useResponsiveImage = ({
  desktop,
  mobile,
  breakpoint = 'sm'
}: useResponsiveImageProps): IGatsbyImageData => {
  // @ts-ignore
  return withArtDirection(getImage(desktop), [
    {
      media: `(max-width: ${parseSize(breakpoints[breakpoint] - 1)})`,
      image: getImage(mobile)
    }
  ])
}

export default useResponsiveImage
