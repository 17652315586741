import * as React from 'react'
import { ThemeProvider, WhatsApp } from '@superlogica/design-ui'
import { GlobalStyle } from 'styles'

import theme from 'styles/theme.json'

import vectors from 'assets/svg/vectors'
import { Footer, Header } from 'components/common'
import { ConfigProvider } from 'contexts'

import * as S from './Default.styles'

const DefaultLayout: React.FC = ({ children }) => (
  <ConfigProvider>
    <ThemeProvider theme={theme} svgLibrary={{ vectors }}>
      <GlobalStyle />
      <Header />
      <S.Main role="main">{children}</S.Main>
      <WhatsApp phone="5519981780025" right={32} bottom={32} />
      <Footer />
    </ThemeProvider>
  </ConfigProvider>
)

export default DefaultLayout
