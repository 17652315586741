import { createGlobalStyle } from 'styled-components'
import { media, parseColor } from '@superlogica/design-ui'

import * as helpers from './helpers'

const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }

  html,
  body,
  #___gatsby,
  #gatsby-focus-wrapper {
    width: 100%;
    height: 100%;
  }

  ${({ theme }) => `
    body {
      color: ${parseColor(theme.colors.textDefault)};
      font-family: ${theme.fonts.default}, sans-serif;
    }

    a {
      transition: all ease 0.4s;
      color: ${parseColor(theme.colors.primary)};

      &:hover {
        color: ${parseColor(theme.colors.primary02)};
      }
    }
  `}

  ul, ol {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }

  .sdl {
    ${helpers.gutter}

    ${media.lessThan('lg')} {
      &-lg {
        ${helpers.gutter}
      }
    }

    ${media.lessThan('md')} {
      &-md {
        ${helpers.gutter}
      }
    }

    ${media.lessThan('sm')} {
      &-sm {
        ${helpers.gutter}
      }
    }

    ${media.lessThan('xs')} {
      &-xs {
        ${helpers.gutter}
      }
    }

    ${media.lessThan('xxs')} {
      &-xxs {
        ${helpers.gutter}
      }
    }
  }

  ${media.lessThan('xs')} {
    .swiper-partial {
      .swiper-slide {
        width: 95% !important;
      }
    }
  }
`

export default GlobalStyle
