/* eslint-disable prettier/prettier */
import ArrowDown from './arrow-down.svg'
import ArrowLeft from './arrow-left.svg'
import ArrowRight from './arrow-right.svg'
import ArrowUp from './arrow-up.svg'
import BrandBowe from './brand-bowe.svg'
import BrandColor from './brand-color.svg'
import BrandEsfera from './brand-esfera.svg'
import BrandComparta from './brand-comparta.svg'
import BrandOmega from './brand-omega.svg'
import Brand from './brand.svg'
import Close from './close.svg'
import CommonFileTextCash from './common-file-text-cash.svg'
import ExternalLink from './external-link.svg'
import Lamp from './lamp.svg'
import Less from './less.svg'
import LightBulbShine1 from './light-bulb-shine-1.svg'
import Play from './play.svg'
import Plus from './plus.svg'
import RenewableEnergyBatteryCharge1 from './renewable-energy-battery-charge-1.svg'
import RenewableEnergyEarth from './renewable-energy-earth.svg'
import RenewableEnergyEcoBarrel from './renewable-energy-eco-barrel.svg'
import RenewableEnergySolarPanel from './renewable-energy-solar-panel.svg'
import SocialFacebook from './social-facebook.svg'
import SocialInstagram from './social-instagram.svg'
import SocialLinkedin from './social-linkedin.svg'
import SocialTwitter from './social-twitter.svg'
import SocialYoutube from './social-youtube.svg'
import Thunderbolt from './thunderbolt.svg'
import Whatsapp from './whatsapp.svg'

const vectors = {
  'arrow-down': ArrowDown,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'arrow-up': ArrowUp,
  'brand-bowe': BrandBowe,
  'brand-color': BrandColor,
  'brand-esfera': BrandEsfera,
  'brand-comparta': BrandComparta,
  'brand-omega': BrandOmega,
  'brand': Brand,
  'close': Close,
  'common-file-text-cash': CommonFileTextCash,
  'external-link': ExternalLink,
  'lamp': Lamp,
  'less': Less,
  'light-bulb-shine-1': LightBulbShine1,
  'play': Play,
  'plus': Plus,
  'renewable-energy-battery-charge-1': RenewableEnergyBatteryCharge1,
  'renewable-energy-earth': RenewableEnergyEarth,
  'renewable-energy-eco-barrel': RenewableEnergyEcoBarrel,
  'renewable-energy-solar-panel': RenewableEnergySolarPanel,
  'social-facebook': SocialFacebook,
  'social-instagram': SocialInstagram,
  'social-linkedin': SocialLinkedin,
  'social-twitter': SocialTwitter,
  'social-youtube': SocialYoutube,
  'thunderbolt': Thunderbolt,
  'whatsapp': Whatsapp,
}

export type VectorsProps = keyof typeof vectors

export default vectors