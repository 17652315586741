export type Company = {
  name: string
  discount: number
}

const companies = {
  default: {
    name: 'Default',
    discount: 12
  }
  // cemig: {
  //   name: 'CEMIG',
  //   discount: 12
  // }
  // 'cpfl-paulista': {
  //   name: 'CPFL Paulista',
  //   discount: 12
  // }
}

export type EnergyCompany = keyof typeof companies

export default companies
