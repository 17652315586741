import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Container } from 'react-awesome-styled-grid'

import { Text } from '@superlogica/design-ui'

import Copyright from './Footer.Copyright'

import * as S from './Footer.styled'

interface FooterDataProps {
  allJuridicJson: {
    nodes: {
      label: string
      href: string
      target?: TargetProps
    }[]
  }
}

const Footer: React.FC = () => {
  const { allJuridicJson } = useStaticQuery<FooterDataProps>(
    graphql`
      query {
        allJuridicJson {
          nodes {
            label
            href
            target
          }
        }
      }
    `
  )

  return (
    <S.Wrapper>
      <Container>
        <S.Press>
          <Text size="label04">
            Para mais informações entre em contato com a gente pelos telefones{' '}
            <S.ActionLink href="tel:08007096800" title="Telefone">
              0800 709 6800
            </S.ActionLink>{' '}
            ou{' '}
            <S.ActionLink href="tel:1940096800" title="Telefone">
              (19) 4009 6800
            </S.ActionLink>{' '}
            em horário comercial, das 8h às 18h. Caso queira, você também pode
            conversar com a nossa assessoria de imprensa pelo e-mail{' '}
            <S.ActionLink
              href="mailto:imprensa@superlogica.com"
              title="E-mail assessoria de imprensa"
            >
              imprensa@superlogica.com
            </S.ActionLink>
            . <br />
            Endereço: Alameda Rio Negro, 585 - Bloco B - 3º andar - CEP. 06454-000 - Alphaville, Barueri - SP /  Rua Joaquim Vilac nº 501, Vila Teixeira - CEP. 13.032-385 - Campinas-SP.
          </Text>
        </S.Press>
        <Copyright items={allJuridicJson.nodes} />
      </Container>
    </S.Wrapper>
  )
}

export default Footer
