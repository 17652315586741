import * as React from 'react'
import { Container } from 'react-awesome-styled-grid'
import { Vector } from '@superlogica/design-ui'

import { Link } from 'components/helpers'
import * as S from './Header.styled'

const Header: React.FC = () => (
  <S.Wrapper>
    <Container>
      <S.Bar>
        <S.Brand>
          <Link to="/" title="Superlógica">
            <Vector name="brand-color" width={124} height={19} />
          </Link>
        </S.Brand>
      </S.Bar>
    </Container>
  </S.Wrapper>
)

export default Header
