import styled from 'styled-components'
import { media, parseColor } from '@superlogica/design-ui'

export const Brand = styled.div`
  &:not(:first-of-type) {
    padding-left: 16px;
  }

  &:not(:last-of-type) {
    padding-right: 16px;

    ${({ theme: { colors } }) => `
      border-right: 1px solid ${parseColor(colors.babar100)};
    `}
  }
`

export const Bar = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
`

export const Wrapper = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  height: 70px;
  overflow: hidden;

  ${({ theme: { colors } }) => `
    background-color: ${parseColor(colors.babar0)};
    border-bottom: 1px solid ${parseColor(colors.babar75)};
  `}

  ${media.lessThan('xs')} {
    height: 60px;
  }
`
